import { FC, useEffect, useState } from 'react';
import { Card, Row, Col, Skeleton, Alert, Spin } from 'antd';
import { useQuery, useQueryClient } from 'react-query';

import Asset from 'components/Asset/Asset';
import Box from 'components/Box/Box';
import UploadFile from 'components/UploadFile/UploadFile';
import { IAsset } from 'types/types';
import API from 'utils/API';
import useQueryParams from 'hooks/query-params';

type AssetsProps = {
    onDragStart: (e: React.DragEvent<HTMLDivElement>, assetId: string) => void;
    ProductsQuantity: number;
};

type IAssetsProcessingStatus = 'idle' | 'processing' | 'done' | 'error';

const instructions = [
    'Omdat je meerdere producten hebt besteld, kun je zelf kiezen of je één of meerdere afbeeldingen wilt laten printen.',
    'Je maakt extra varianten met de knop "Voeg product toe".',
    'De bestanden die je upload worden omgezet naar afbeeldingen. Deze worden hier links getoond.',
    'Het is belangrijk dat je de afbeeldingen toewijst aan een product door het bestand er op te slepen. Zo controleer je eenvoudig hoe je print er uit komt te zien.',
    'Je kunt een grote preview bekijken door op de afbeelding van een product te klikken. Deze preview sluit je met het kruisje rechtsboven.',
];

const instructionsForOneProduct = instructions.slice(2, 5);

const AssetsProcessingStatus: FC = () => {
    const queryClient = useQueryClient();
    const { sessionId } = useQueryParams();
    const {
        data: { status } = { status: 'idle' },
        isError,
        isLoading: isLoadingStatus,
    } = useQuery<{ status: IAssetsProcessingStatus }>(
        'assetsProcessingStatus',
        () => API.getAssetsProcessingStatus(sessionId),
        {
            refetchInterval: (data) => {
                if (data?.status === 'processing') {
                    return 500
                }
                return false
            },
            onSuccess(data) {
                if (data.status === 'done') {
                    queryClient.invalidateQueries('assets');
                    API.postResetAssetsProcessingStatus(sessionId);
                }
            },
            enabled: !!sessionId,
        }
    );


    const [visible, setVisible] = useState(false)
    const isProcessing = status === 'processing';
    const hasDone = status === 'done';
    const hasErrors = status === 'error' || isError;
    const isIdle = status === 'idle';

    useEffect(() => {
        if (isIdle) return // don't show anything if idle
        setVisible(true)
        if (isProcessing) return // don't hide if processing
        const timeOut = setTimeout(() => setVisible(false), 5000)
        return () => clearTimeout(timeOut)
    }, [isIdle, isProcessing])

    // En: Assets processed, Processing assets, Error when processing assets

    return (
        <Spin spinning={isLoadingStatus} delay={500}>
            {hasDone && visible && <Alert message="Verwerking voltooid" type="success" closable onClose={() => setVisible(false)} />}
            {isProcessing && visible && <Alert message="Bezig met verwerken..." type="info" closable onClose={() => setVisible(false)} />}
            {hasErrors && visible && <Alert message="Fout bij het verwerken van activa" type="error" closable onClose={() => setVisible(false)} />}
        </Spin>
    )

};

const Assets: FC<AssetsProps> = ({ onDragStart, ProductsQuantity }: AssetsProps) => {
    const { sessionId } = useQueryParams();
    const { data: assets, isLoading, isError, error } = useQuery<IAsset[]>('assets', () => API.getAssets(sessionId), {
        enabled: !!sessionId,
    });
    const { data: { status } = { status: 'idle' }, isLoading: isLoadingStatus } = useQuery<{
        status: IAssetsProcessingStatus;
    }>('assetsProcessingStatus', () => API.getAssetsProcessingStatus(sessionId), {
        enabled: !!sessionId,
    });

    const isLoadingAssets = isLoading || isLoadingStatus;
    const isProcessing = status === 'processing' || isLoadingAssets;

    if (isError) {
        return <Box>Error</Box>;
    }

    return (
        <>
            <Card style={{ overflow: 'auto', maxHeight: '100vh' }}>
                <AssetsProcessingStatus />
                <UploadFile />
                <Row gutter={8} style={{ marginTop: '1rem' }}>
                    {isProcessing && (
                        <Col className="gutter-row" span={12}>
                            <Skeleton.Image active style={{ height: '171px', width: '130px' }} />
                        </Col>
                    )}
                    {assets?.length === 0
                        ? null
                        : assets?.map((asset) => (
                            <Col key={asset.id} className="gutter-row" span={12}>
                                <Asset
                                    assetId={asset.id}
                                    previewImage={asset.previewImage}
                                    onDragStart={onDragStart}
                                />
                            </Col>
                        ))}
                </Row>
                <Box color="#6b7280" textAlign="left" style={{ marginTop: '1rem' }}>
                    {/* Drag and drop assets to the product */}
                    <h4>
                        Uitleg:
                    </h4>
                    <ul>
                        {ProductsQuantity > 1 ? instructions.map((instruction, index) => (
                            <li key={index} style={{
                                marginBottom: '0.5rem',
                            }}>{instruction}</li>
                        )) : instructionsForOneProduct.map((instruction, index) => (
                            <li key={index} style={{
                                marginBottom: '0.5rem',
                            }} >{instruction}</li>
                        ))}
                    </ul>
                </Box>
            </Card>
        </>
    );
};

export default Assets;
