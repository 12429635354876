import * as R from 'ramda';
import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Card, Col, Row, Spin, Button, notification } from 'antd';
import { useQuery } from 'react-query';

import Assets from 'components/Assets';
import ProductCard from 'components/ProductCard/ProductCard';
import Box from 'components/Box/Box';
import API from 'utils/API';
import useQueryParams from 'hooks/query-params';
import useFetchConfig from 'hooks/fetch-config';
import { convertPdfToImage, getWidthForPreviewImage } from 'services/fileService';
import { SContainer, SLoaderContainer, SSticky } from './Uploader.styles';
import { createEmptyProduct } from 'utils/misc';

const getColsBySidesCount = (sidesCount) => {
  if (sidesCount === 1) {
    return 8;
  }
  if (sidesCount === 2) {
    return 12;
  }
  return 24;
};

const Uploader = () => {
  const { sessionId } = useQueryParams();
  const [products, setProducts] = useState([]);
  const [savingProducts, setSavingProducts] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [shouldHighlighDropArea, setShouldHighlighDropArea] = useState(false);
  const isAutomatedWorkFlowRef = useRef(false);
  const { config, fetch, loading, error } = useFetchConfig({
    onSuccess: useCallback(async () => {
      const data = await API.getStoredData(sessionId);
      const fetchedProducts = R.propOr([], 'products')(data);
      setProducts(fetchedProducts);
    }, [sessionId]),
  });

  const { data: assets = [] } = useQuery('assets', () => API.getAssets(sessionId), {
    enabled: !!sessionId,
  });

  const totalNo = useMemo(() => products.reduce((acc, { count }) => acc + count, 0), [products]);

  const handleChange = (config = {}, productIdx, sideIdx) => {
    setProducts((prev) =>
      prev.map((product, index) =>
        productIdx === index
          ? {
              ...product,
              sides: (product.sides || []).map((side, ind) =>
                sideIdx === ind ? { ...side, ...config } : side,
              ),
            }
          : product,
      ),
    );
  };

  const handleDeleteSideAsset = useCallback((productIndex, sideIndex) => {
    setProducts((prev) => {
      const sides = prev[productIndex].sides;
      const side = prev[productIndex].sides[sideIndex];
      const updatedSide = R.omit(['previewImage', 'previewImageHash'], {
        ...side,
        asset: undefined,
        assetId: undefined,
      });
      const updateSides = R.update(sideIndex, updatedSide, sides);
      return R.update(productIndex, { ...prev[productIndex], sides: updateSides }, prev);
    });
  }, []);

  const handleIncrement = useCallback(
    (targetIdx) => {
      if (totalNo >= config?.quantity) {
        return;
      }
      setProducts((prev) =>
        prev.map((doc, index) => (targetIdx === index ? { ...doc, count: doc.count + 1 } : doc)),
      );
    },
    [totalNo, config?.quantity],
  );

  const handleDecrement = useCallback((targetIdx) => {
    setProducts((prev) =>
      prev.map((doc, index) =>
        targetIdx === index && doc.count > 0 ? { ...doc, count: doc.count - 1 } : doc,
      ),
    );
  }, []);

  const handleDeleteProduct = useCallback((productIdx) => {
    setProducts((prev) => prev.filter((_, index) => index !== productIdx));
  }, []);

  const handleAddProduct = useCallback(() => {
    const sidesCount = config?.sidesCount;
    const product = createEmptyProduct(null, sidesCount);
    setProducts((products) => [...products, product]);
  }, [config]);

  const handleImagePreviewChange = async (file, productIdx, sideIdx) => {
    if (!file) {
      return;
    }
    const sideId = products[productIdx]['sides'][sideIdx].id;
    const image = await convertPdfToImage(file, {
      quality: 0.5,
      width: getWidthForPreviewImage(file),
    });
    const previewImage = await API.updatePreviewImage(sessionId, {
      file: image,
      key: sideId,
    });
    handleChange(
      {
        previewImage,
        previewImageHash: Date.now(),
      },
      productIdx,
      sideIdx,
    );
  };

  const handleSelectAssetForSide = useCallback(
    ({ assetId, productIndex, sideIndex }) => {
      if (!assetId) {
        throw new Error('invalid asset');
      }
      const side = products[productIndex]['sides'][sideIndex];
      const asset = assets.find(({ id }) => id === assetId);
      const newSide = {
        ...side,
        assetId,
        asset,
      };
      setProducts((products) =>
        products.map((p, index) => {
          if (index !== productIndex) {
            return p;
          }
          return {
            ...p,
            sides: p.sides.map((side, idx) => (idx === sideIndex ? newSide : side)),
          };
        }),
      );
    },
    [products, assets],
  );

  const onDragStart = (event, assetId) => {
    event.dataTransfer.setData('assetId', assetId);
    setShouldHighlighDropArea(true);
  };

  const onDragOver = (event) => {
    event.preventDefault();
    setShouldHighlighDropArea(false);
  };

  const onDrop = function (event, { productIndex, sideIndex }) {
    event.preventDefault();
    const assetId = event.dataTransfer.getData('assetId');
    handleSelectAssetForSide({ productIndex, sideIndex, assetId });
    setShouldHighlighDropArea(false);
  };

  const automateUserWorkflowInSingleSide = useCallback(() => {
    const side = products[0]?.sides[0];
    if (!side || !side?.id) return;
    const asset = assets[0];
    handleSelectAssetForSide({
      assetId: asset.id,
      productIndex: 0,
      sideIndex: 0,
    });
  }, [products, assets, handleSelectAssetForSide]);

  const shouldDisplayUpload = useMemo(() => {
    return totalNo < config?.quantity ? true : false;
  }, [config?.quantity, totalNo]);

  useEffect(() => {
    fetch(sessionId);
  }, [sessionId, fetch]);

  useEffect(() => {
    const shouldSkipAutomateWorkflow = () => {
      if (isAutomatedWorkFlowRef.current) return true; // workflow has run before stop further automation
      if (!config?.sidesCount || config?.sidesCount > 1) return true; // skip if more than one side
      if (!products.length) return true; // no products, no need to automate or not fetched yet
      if (!assets.length) return true; // no assets, no need to automate or not fetched yet
      if (products[0]?.sides[0]?.assetId) return true; // side already has an asset
      return false;
    };
    if (shouldSkipAutomateWorkflow()) return;
    automateUserWorkflowInSingleSide();
    isAutomatedWorkFlowRef.current = true;
  }, [config?.sidesCount, products, assets, automateUserWorkflowInSingleSide]);

  useEffect(() => {
    if (!Object.keys(config).length) {
      return;
    }
    setSavingProducts(true);
    const productsToSend = products.map(({ sides, ...rest }) => ({
      ...rest,
      sides: sides.map(({ file, asset, ...rest }) => ({ ...rest })),
    }));
    API.updateProducts(sessionId, productsToSend).finally(() => setSavingProducts(false));
  }, [products, config, sessionId]);

  const validateConfirmation = useCallback(() => {
    const hasNoProducts = products.length === 0;
    const hasNoAssets = products.every((product) => product.sides.every((side) => !side.assetId));
    const missingAsset = products.some((product) => product.sides.some((side) => !side.assetId));
    const missingProduct = config?.quantity > totalNo;
    if (hasNoProducts || missingProduct) {
      // Add more products or increase the number of the product
      throw new Error('Voeg meer producten toe of verhoog het aantal bij het product');
    }

    if (hasNoAssets || missingAsset) {
      // // Please add an asset to each side
      throw new Error(
        'Er mist een afbeelding op 1 of meer producten. Sleep een afbeelding uit de linker kolom naar het betreffende product.',
      );
    }
  }, [products, config?.quantity, totalNo]);

  const handleConfirm = useCallback(async () => {
    try {
      validateConfirmation();
      setIsConfirming(true);
      const { message = '' } = await API.postConfig(sessionId);
      if (message !== 'success') {
        // we could not confirm your products
        throw new Error('We konden uw producten niet bevestigen');
      }
      notification.open({
        message: 'Je bestanden zijn gecontroleerd', // Successfully confirmed
        description: 'Er zijn geen problemen gevonden', // We have confirmed your products
        duration: 5,
        type: 'success',
      });
    } catch (error) {
      notification.open({
        message: 'Fout', // Error
        description: error.message,
        duration: 5,
        type: 'error',
      });
    } finally {
      setIsConfirming(false);
    }
  }, [validateConfirmation]);

  return (
    <>
      <SContainer>
        {loading ? (
          'Loading'
        ) : (
          <>
            {error ? (
              <h3>{error}</h3>
            ) : (
              <Box>
                <SSticky>
                  <Box display="flex" alignContent={'center'} justifyContent={'space-between'}>
                    <Box>
                      <h3>
                        Oplage toegewezen {totalNo} / {config?.quantity}
                      </h3>
                    </Box>
                    <Box>
                      <Button
                        type="primary"
                        size="middle"
                        onClick={handleConfirm}
                        loading={isConfirming}
                      >
                        Bestanden controleren
                      </Button>
                    </Box>
                  </Box>
                </SSticky>
                <Row gutter={[16, 16]}>
                  <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 6 }}>
                    <Assets onDragStart={onDragStart} ProductsQuantity={config?.quantityup || 1} />
                  </Col>
                  <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 18 }}>
                    <Card style={{ overflow: 'auto', maxHeight: '100vh' }}>
                      {shouldDisplayUpload && (
                        <Button
                          type="primary"
                          size="middle"
                          style={{ marginBottom: '1rem' }}
                          onClick={handleAddProduct}
                          icon={<PlusOutlined />}
                        >
                          Voeg product toe
                        </Button>
                      )}
                      <Row className="gutter-row" gutter={[16, 16]}>
                        {products.map((product, index) => {
                          return (
                            <Col
                              className="gutter-row"
                              xs={{ span: 24 }}
                              md={{ span: getColsBySidesCount(config?.sidesCount) }}
                              key={index}
                            >
                              <ProductCard
                                key={product.id}
                                number={index + 1}
                                productIndex={index}
                                product={product}
                                config={config}
                                count={product.count}
                                onDragOver={onDragOver}
                                onDrop={onDrop}
                                onChange={({ file, actions }, sideIndex) => {
                                  handleImagePreviewChange(file, index, sideIndex);
                                  handleChange({ actions }, index, sideIndex);
                                }}
                                onIncrement={() => handleIncrement(index)}
                                onDecrement={() => handleDecrement(index)}
                                onDeleteProduct={() => handleDeleteProduct(index)}
                                onDeleteSide={(sideIndex) =>
                                  handleDeleteSideAsset(index, sideIndex)
                                }
                                shouldHighlighDropArea={shouldHighlighDropArea}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Box>
            )}
          </>
        )}
      </SContainer>
      {savingProducts && (
        <SLoaderContainer>
          <Spin size="large" />
        </SLoaderContainer>
      )}
    </>
  );
};

Uploader.propTypes = {};

export default Uploader;
