import * as dateFns from 'date-fns';
import axios from 'axios';
import { IAsset, IProduct } from 'types/types';

const BASE_URL = process.env.REACT_APP_PRINT_API_URL;

type OPTIONS = {
  file: File
  key: string;
}

type API_TYPE = {
  fetchConfig: (sessionId: string) => Promise<any>;
  getStoredData: (sessionId: string) => Promise<IProduct[]>;
  updateProducts: (sessionId: string, products: IProduct[]) => Promise<any>;
  getAssets: (sessionId: string) => Promise<any>;
  getAssetsProcessingStatus: (sessionId: string) => Promise<any>;
  postResetAssetsProcessingStatus: (sessionId: string) => Promise<any>;
  deleteAsset: (sessionId: string, assetId: string) => Promise<any>;
  updatePreviewImage: (sessionId: string, options: OPTIONS) => Promise<any>;
  postConfig: (sessionId: string) => Promise<any>;
};

const API: API_TYPE = {
  fetchConfig: async (sessionId) => {
    const res = await axios.get(`${BASE_URL}/${sessionId}/config`);
    const config = res?.data?.config || null;
    if (!config) {
      throw new Error('Invalid or expired session');
    }
    return config;
  },
  getStoredData: (sessionId) => {
    return axios.get(`${BASE_URL}/${sessionId}/data`).then(({ data }) => {
      return data;
    });
  },
  updateProducts: (sessionId, products) => {
    return axios
      .post(`${BASE_URL}/${sessionId}/data/products`, products)
      .then(({ data }) => {
        return data;
      });
  },
  getAssets: (sessionId) => {
    return axios
      .get(`${BASE_URL}/${sessionId}/data/assets`)
      .then(({ data: assets }) => {
        return assets.sort((assetA: IAsset, assetB: IAsset) => {
          return dateFns.compareDesc(
            dateFns.parse(
              assetA.createdAt,
              'yyyyMMdd:HHmmssSSS',
              new Date(),
            ),
            dateFns.parse(
              assetB.createdAt,
              'yyyyMMdd:HHmmssSSS',
              new Date(),
            ),
          );
        });
      });
  },
  getAssetsProcessingStatus: (sessionId) => {
    return axios
      .get(`${BASE_URL}/${sessionId}/data/assets/status`)
      .then(({ data }) => {
        return data;
      });
  },
  postResetAssetsProcessingStatus: (sessionId) => {
    return axios
      .post(`${BASE_URL}/${sessionId}/data/assets/status`, {
        status: 'idle',
      })
  },
  deleteAsset: (sessionId, assetId) => {
    return axios
      .delete(`${BASE_URL}/${sessionId}/data/assets/${assetId}`)
      .then(({ data }) => {
        return data;
      });
  },
  updatePreviewImage: (sessionId, { file, key}) => {
    return axios
      .post(`${BASE_URL}/${sessionId}/previews`, {
        file,
        key,
      })
      .then(({ data: previewUrl }) => previewUrl);
  },
  postConfig: (sessionId) => {
    return axios.post(`${BASE_URL}/confirm/${sessionId}`).then(({ data }) => {
      return data;
    });
  },
};

export default API;
