import { isNil } from 'ramda';
import { MoreOutlined } from '@ant-design/icons';
import { Card, Col, Dropdown, Row, Typography } from 'antd';
import { RiDragDropLine } from 'react-icons/ri';

import Box from 'components/Box/Box';
import Counter from 'components/Counter/Counter';
import PdfEditor from 'components/PdfEditor/PdfEditor';
import { SCard, SCardContent } from './ProductCard.styles';

const ProductNavBar = ({
  onDeleteProduct,
  onIncrement,
  onDecrement,
  count,
  productIndex,
  number,
}) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={'1rem'}>
      <Typography.Title level={5}>Product {number}</Typography.Title>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Counter count={count} onIncrement={onIncrement} onDecrement={onDecrement} />
        <Dropdown
          menu={{
            items: [
              {
                key: 'delete',
                onClick: () => onDeleteProduct(productIndex),
                label: 'Verwijderen',
              },
            ],
          }}
          trigger={['click']}
        >
          <MoreOutlined
            style={{ fontSize: 24, cursor: 'pointer', marginLeft: '1rem' }}
            onClick={(e) => e.preventDefault()}
          />
        </Dropdown>
      </Box>
    </Box>
  );
};

const overlayStyle = {
  background: 'rgba(0, 0, 0, 0.3)',
};


const getColsBySideCountsForLgScreen = (sidesCount) => {
  switch (sidesCount) {
    case 1:
      return 24;
    case 2:
      return 12;
    case 3:
      return 8;
    case 4:
      return 6;
    default:
      return 8;
  }
};

const ProductCard = ({
  product,
  productIndex,
  config,
  number,
  count,
  onChange: handlePdfEditChange,
  onIncrement,
  onDecrement,
  onDeleteProduct,
  onDeleteSide,
  onDragOver,
  onDrop,
  shouldHighlighDropArea,
}) => {
  return (
    <SCard>
      <ProductNavBar
        onDeleteProduct={onDeleteProduct}
        onIncrement={onIncrement}
        onDecrement={onDecrement}
        count={count}
        productIndex={productIndex}
        number={number}
      />
      <Row gutter={[16, 16]}>
        {(product.sides || []).map((side, index) => (
          <Col className="gutter-row" lg={{ span: getColsBySideCountsForLgScreen(config?.sidesCount) }} key={index}>
            <Card key={side.id} bodyStyle={{ padding: 0 }}>
              <SCardContent>
                {isNil(side.assetId) ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    onDragOver={(e) => onDragOver(e)}
                    onDrop={(e) => onDrop(e, { productIndex, sideIndex: index })}
                    style={shouldHighlighDropArea ? overlayStyle : undefined}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <RiDragDropLine size={50} />
                      <Box margin="0.5rem 0" textAlign={'center'}>
                        {/* Drop asset */}
                        Sleep hier een afbeelding naar toe
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <>
                    <PdfEditor
                      previewImage={side.previewImage}
                      previewImageHash={side.previewImageHash}
                      file={side.asset.previewFile}
                      filename={side.asset?.filename}
                      width={side.asset?.dimensions?.width}
                      height={side.asset?.dimensions?.height}
                      actions={side.actions}
                      onChange={(actions, file) => {
                        handlePdfEditChange({ actions, file }, index);
                      }}
                      onDelete={() => onDeleteSide(index)}
                      config={config}
                    />
                  </>
                )}
              </SCardContent>
            </Card>
          </Col>
        ))}
      </Row>
    </SCard>
  );
};

ProductCard.propTypes = {};

export default ProductCard;
